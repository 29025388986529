var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _vm.show
      ? _c(
          "div",
          { staticClass: "bgback" },
          [_c("van-loading", { attrs: { size: "68" } })],
          1
        )
      : _vm._e(),
    !_vm.show
      ? _c("div", { staticClass: "topcenters" }, [
          _c("div", { staticClass: "topcenter" }, [
            _c("div", [
              _c("i", {
                staticClass: "el-icon-arrow-left",
                staticStyle: { "font-size": "30px", "margin-right": "20px" },
                on: { click: _vm.backbtn }
              })
            ]),
            _vm._m(0),
            _c("div", { staticClass: "user" }, [
              _c("div", { staticClass: "labelUser" }, [_vm._v("客户姓名：")]),
              _c("span", [_vm._v(_vm._s(_vm.uesername.name))]),
              _c("div", { staticClass: "labelUser" }, [_vm._v("联系电话：")]),
              _c("span", [_vm._v(_vm._s(_vm.uesername.phone))]),
              _c("div", { staticClass: "labelUser" }, [_vm._v("顺丰单号：")]),
              _c("span", [_vm._v(_vm._s(_vm.uesername.orderNoExpress))])
            ]),
            _vm.yuFuOrdershow
              ? _c(
                  "table",
                  { staticClass: "tablebox" },
                  [
                    _vm._m(1),
                    _c("tr", { staticClass: "sellbox padtr tl" }, [
                      _c("th", { attrs: { colspan: "8" } }, [
                        _c("div", { staticClass: "labelth" }, [
                          _vm._v("开单时间：")
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.yuFuOrder.date))]),
                        _c("div", { staticClass: "labelth" }, [
                          _vm._v("物料单号：")
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.yuFuOrder.orderNo))])
                      ])
                    ]),
                    _vm._m(2),
                    _vm._l(_vm.yuFuOrder.itemList, function(items, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(index + 1))]),
                        _c("td", [_vm._v(_vm._s(items.name))]),
                        _c("td", [_vm._v(_vm._s(items.weightZeZu))]),
                        _c("td", [_vm._v(_vm._s(items.price))]),
                        _c("td", [_vm._v(_vm._s(items.money))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(items.status ? items.status : "提料不结价")
                          )
                        ]),
                        _c("td", [_vm._v(_vm._s(items.priceKind))]),
                        _c("td", [_vm._v(_vm._s(_vm.yuFuOrder.date))])
                      ])
                    }),
                    _vm._m(3),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("提料克重")]),
                      _c("td", [_vm._v(_vm._s(_vm.yuFuOrder.weightZeZu))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("预收比例")]),
                      _c("td", [_vm._v(_vm._s(_vm.yuFuOrder.percentageYuFu))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("仓租设置")]),
                      _c("td", [_vm._v(_vm._s(_vm.yuFuOrder.storeSetting))]),
                      _c("td", { staticClass: "bggray" }),
                      _c("td")
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("预收金额")]),
                      _c("td", { attrs: { colspan: "7" } }, [
                        _c("span", { staticClass: "f26" }, [
                          _vm._v(_vm._s(_vm.yuFuOrder.moneyTotal))
                        ])
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("备注")]),
                      _c("td", { attrs: { colspan: "8" } }, [
                        _c("span", { staticClass: "f26" }, [
                          _vm._v(_vm._s(_vm.yuFuOrder.intro))
                        ])
                      ])
                    ])
                  ],
                  2
                )
              : _vm._e(),
            _vm.yapanOrdershow
              ? _c(
                  "table",
                  { staticClass: "tablebox" },
                  [
                    _vm._m(4),
                    _c("tr", { staticClass: "sellbox padtr tl" }, [
                      _c("th", { attrs: { colspan: "8" } }, [
                        _c("div", { staticClass: "labelth" }, [
                          _vm._v("开单时间：")
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.yapanOrder.dateYaPan))]),
                        _c("div", { staticClass: "labelth" }, [
                          _vm._v("物料单号：")
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.yapanOrder.orderNo))])
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("提料克重")]),
                      _c("td", { attrs: { colspan: "7" } }, [
                        _vm._v(_vm._s(_vm.yapanOrder.weight))
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("开始时间")]),
                      _c("td", [_vm._v(_vm._s(_vm.yapanOrder.storeDateStart))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("结算时间")]),
                      _c("td", [_vm._v(_vm._s(_vm.yapanOrder.storeDateEnd))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("提料天数")]),
                      _c("td", [_vm._v(_vm._s(_vm.yapanOrder.storeDay))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("仓租设置")]),
                      _c("td", [_vm._v(_vm._s(_vm.yapanOrder.storeSetting))])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("累计仓租")]),
                      _c("td", [_vm._v(_vm._s(_vm.yapanOrder.moneyStore))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("首饰折价")]),
                      _c("td", [_vm._v(_vm._s(_vm.yapanOrder.moneyTiChun))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("快递费")]),
                      _c("td", [_vm._v(_vm._s(_vm.yapanOrder.moneyYunFei))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("保险费")]),
                      _c("td", [_vm._v(_vm._s(_vm.yapanOrder.moneyBaoXiang))])
                    ]),
                    _vm._m(5),
                    _vm._l(_vm.yapanOrder.referenceInfoList, function(
                      items,
                      index
                    ) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(index + 1))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate_")(items.time, "yyyy-MM-dd")
                            )
                          )
                        ]),
                        _c("td", [_vm._v(_vm._s(items.referenceWeight))]),
                        _c("td", [_vm._v(_vm._s(items.price))]),
                        _c("td", [_vm._v(_vm._s(items.money))]),
                        _c("td", { attrs: { colspan: "3" } })
                      ])
                    }),
                    _vm._m(6),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("预收金额")]),
                      _c("td", { attrs: { colspan: "7" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.yapanOrder.moneyYuFu))])
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("累计补仓")]),
                      _c("td", { attrs: { colspan: "7" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.yapanOrder.moneyBuCang))])
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("剩余应付")]),
                      _c("td", { attrs: { colspan: "7" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.yapanOrder.moneyShenYu))])
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("备注")]),
                      _c("td", { attrs: { colspan: "7" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.yapanOrder.intro))])
                      ])
                    ])
                  ],
                  2
                )
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tiptop" }, [
      _c("i", { staticClass: "el-icon-warning-outline" }),
      _vm._v("用户可横向滚动查看更多详情")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "sellbox" }, [
      _c("th", { staticClass: "fb f26", attrs: { colspan: "8" } }, [
        _vm._v("提料预收单")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "bggray" }, [
      _c("th", [_vm._v("序号")]),
      _c("th", [_vm._v("类别")]),
      _c("th", [_vm._v("折足克重")]),
      _c("th", [_vm._v("预估金价")]),
      _c("th", [_vm._v("金额")]),
      _c("th", [_vm._v("状态")]),
      _c("th", [_vm._v("定价渠道")]),
      _c("th", [_vm._v("定价时间")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "8" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "sellbox" }, [
      _c("th", { staticClass: "fb f26", attrs: { colspan: "8" } }, [
        _vm._v("提料结算单")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "bggray" }, [
      _c("th", { staticClass: "indexWidth" }, [_vm._v("序号")]),
      _c("th", { staticClass: "styleth" }, [_vm._v("平仓时间")]),
      _c("th", { staticClass: "styleth" }, [_vm._v("平仓克重")]),
      _c("th", { staticClass: "styleth" }, [_vm._v("平仓价格")]),
      _c("th", { staticClass: "styleth" }, [_vm._v("平仓金额")]),
      _c("th", { staticClass: "styleth", attrs: { colspan: "3" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "8" } })])
  }
]
render._withStripped = true

export { render, staticRenderFns }